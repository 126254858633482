<i18n>
ru:
  cancel: Отмена
  confirmNo: Нет
  confirmYes: Да
  logoutTimeoutNo: Попробовать снова
  logoutTimeoutYes: Выйти из аккаунта
  ok: Ок
ua:
  cancel: Скасування
  confirmNo: Hi
  confirmYes: Так
  logoutTimeoutNo: Пробувати знову
  logoutTimeoutYes: Вихід із системи
  ok: Ок
us:
  cancel: Cancel
  confirmNo: No
  confirmYes: Yes
  logoutTimeoutNo: Try again
  logoutTimeoutYes: Logout
  ok: Ok
</i18n>

<template>
  <div class="v-popup-head v-title v-justify-content-center v-font-weight-600">
    <div v-if="type === 'warning'">
      <span v-html="title ? sanitize(title) : translate('messagePopup.warning')" />
    </div>
    <div
      v-else-if="type === 'error'"
      class="v-error-color"
    >
      <span v-html="title ? sanitize(title) : translate('messagePopup.error')" />
    </div>
    <div v-else-if="type === 'success'">
      <span v-html="title ? sanitize(title) : translate('messagePopup.success')" />
    </div>
    <div v-if="type !== 'warning' && type !== 'error' && type !== 'success' && title">
      <span v-html="sanitize(title)" />
    </div>
  </div>

  <div class="v-popup-body v-mb-sm">
    <span v-html="sanitize(message)" />
  </div>

  <div class="v-d-flex v-flex-row v-flex-row-no-wrap v-justify-content-between v-w-100 v-mx-sm">
    <arora-button
      class-name="btn-yes v-flex-50 v-mr-sm v-fz-main"
      :label="yesText ?? (yesOrNo ? translate('confirm.confirmYes') : translate('confirm.ok'))"
      data-test-id="confirm-popup-btn-yes"
      @click="accept"
    />
    <arora-button
      class-name="btn-no v-flex-50 v-fz-main"
      :label="noText ?? (yesOrNo ? translate('confirm.confirmNo') : translate('confirm.cancel'))"
      data-test-id="confirm-popup-btn-no"
      @click="reject"
    />
  </div>
</template>

<script setup lang="ts">
import type { popupConfirmProps } from '~types/popupStore'

const { noFunction, yesFunction } = defineProps<popupConfirmProps>()
const { translate, sanitize } = useI18nSanitized()
const popupStore = usePopupStore()

function accept(): void {
  if (typeof yesFunction === 'function') {
    yesFunction()
  }
  popupStore.closePopup()
}

function reject(): void {
  if (typeof noFunction === 'function') {
    noFunction()
  }
  popupStore.closePopup()
}
</script>
